<template>
  <van-popup
    v-model="show"
    closeable
    position="bottom"
    :style="{ height: '50%', overflow: 'hidden' }"
  >
    <div class="search-modal-container">
      <van-search
        v-model="searchTarget"
        shape="round"
        index
        :placeholder="placeholder"
        class="search-input"
        :autofocus="autofocus"
      />
      <ul class="list-content" v-show="filterList.length > 0">
        <li
          class="list-item"
          @click="selectData(item)"
          v-for="(item, index) in filterList"
          :key="index"
        >
          {{ item }}
        </li>
      </ul>
      <div class="no-results-content" v-show="filterList == 0">
        <div class="no-result-tips item">未搜索到相关内容</div>
        <div class="item">
          <span class="add-tips">直接添加</span>
          <van-button type="default" @click="selectData(searchTarget)" class="add-btn" size="normal" style="min-width:100px;max-width:200px;">{{
            searchTarget
          }}</van-button>
        </div>
      </div>
    </div>
  </van-popup>
</template>
<script>
export default {
  name: "drugSearchModal",
  props: {
    placeholder: {
      type: String,
      default: "请输入",
    },
    searchTargetList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      show: false,
      searchTarget: "",
      autofocus:true
    };
  },
  methods: {
    showModal() {
      this.show = true;
    },
    closeModal() {
      this.show = false;
    },
    selectData(item) {
      this.$emit("confirm", item);
      this.closeModal();
    },
  },
  computed: {
    filterList() {
      return this.searchTargetList.filter(
        (item) => item.indexOf(this.searchTarget) > -1
      );
    },
  },
};
</script>
<style lang="less" scoped>
.search-modal-container {
  position: relative;
  height: 100%;
  color: @color-26;
  font-size: @font-size-small;
  .search-input {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    overflow: hidden;
  }
  .no-results-content,
  .list-content {
    position: absolute;
    overflow-y: scroll;
    top: 220px;
    left: 0;
    width: 100%;
    height: 430px;
  }
  .list-content {
    .list-item {
      height: 80px;
      line-height: 80px;
      border-bottom: 1px solid #eee;
      text-align: center;
      font-size: @font-size-medium-xl;
    }
  }
  .no-results-content {
    .item {
      margin-bottom: 10px;
      text-align: center;
      &.no-result-tips {
        color: #595959;
      }
      .add-tips {
        color: #b4b4b4;
        margin-right: 25px;
      }
    }
  }
}
</style>