<template>
  <div class="test-request-form page">
    <div class="header-container add-border-bottom">
      <div class="banner">
        <img src="../../assets/images/testRequestForm/header-bg.png" alt="" />
      </div>
      <div class="step-container">
        <van-row
          type="flex"
          justify="center"
          align="center"
          class="step-icon-row"
        >
          <van-col span="6">
            <span class="step-icon" :class="{ active: step === 1 }">1</span>
          </van-col>
          <van-col span="12">
            <van-divider
              ><span class="step-icon" :class="{ active: step === 2 }"
                >2</span
              ></van-divider
            >
          </van-col>
          <van-col span="6"
            ><span class="step-icon" :class="{ active: step === 3 }"
              >3</span
            ></van-col
          >
        </van-row>
        <van-row type="flex" justify="center" class="step-message-row">
          <van-col span="6">录入信息</van-col>
          <van-col span="12">确认信息</van-col>
          <van-col span="6">完成</van-col>
        </van-row>
      </div>
    </div>
    <div class="request-form-container">
      <van-form>
        <div class="form-wrapper">
          <div class="form-wrapper-header">
            <span class="title-icon"></span>
            <span class="title">基本信息</span>
          </div>
          <div class="form-wrapper-content">
            <p class="form-wrapper-content-title">检测人信息</p>
            <div class="form-item">
              <div class="form-item-title van-cell--required">姓名</div>
              <van-field
                v-model="patientname"
                name="patientname"
                placeholder="请输入"
                :readonly="!isStepOne"
              />
            </div>
            <div class="form-item">
              <div class="form-item-title van-cell--required">身份证号码</div>
              <van-field
                v-model="ID"
                name="ID"
                placeholder="请输入"
                :readonly="step == 2"
                @keyup="setIdInfo(ID)"
              />
            </div>
            <div class="form-item">
              <div class="form-item-title van-cell--required">性别</div>
              <van-field name="radio">
                <template #input>
                  <van-radio-group
                    v-model="gender"
                    direction="horizontal"
                    :disabled="!isStepOne"
                  >
                    <van-radio name="男">男</van-radio>
                    <van-radio name="女">女</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
            </div>
            <div class="form-item">
              <div class="form-item-title van-cell--required">出生年月</div>
              <van-field
                readonly
                name="datetimePicker"
                :value="birth_date"
                placeholder="请选择"
              >
                <template #button>
                  <span
                    class="btn-choose"
                    v-show="isStepOne"
                    @click="showBirthDatePicker = true"
                    >选择</span
                  >
                </template>
              </van-field>
              <van-popup v-model="showBirthDatePicker" position="bottom">
                <van-datetime-picker
                  type="year-month"
                  :min-date="minBirthDate"
                  :max-date="maxDate"
                  :formatter="formatter"
                  v-model="currentDate"
                  title="选择年月"
                  @confirm="onConfirm"
                  @cancel="showBirthDatePicker = false"
                />
              </van-popup>
            </div>
            <div class="form-item">
              <div class="form-item-title">联系人</div>
              <van-field name="radio">
                <template #input>
                  <van-radio-group
                    v-model="contacts1"
                    direction="horizontal"
                    :disabled="!isStepOne"
                  >
                    <van-radio name="患者本人">患者本人</van-radio>
                    <van-radio name="患者家属">患者家属</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
            </div>
            <div class="form-item" v-if="contacts1">
              <div class="form-item-title van-cell--required">联系人电话</div>
              <van-field
                v-model="phone"
                name="phone"
                :readonly="!isStepOne"
                placeholder="请输入"
              />
            </div>
          </div>
          <div class="form-wrapper-content">
            <p class="form-wrapper-content-title">地址信息</p>
            <div>
              <div class="form-item">
                <div class="form-item-title">联系地址</div>
                <van-field
                  readonly
                  clickable
                  name="district"
                  :value="district"
                  placeholder="城市/区域"
                >
                  <template #button>
                    <span
                      class="btn-choose"
                      v-show="isStepOne"
                      @click="showAreaPicker = true"
                      >选择</span
                    >
                  </template>
                </van-field>
                <van-popup v-model="showAreaPicker" position="bottom">
                  <van-area
                    show-toolbar
                    title="选择省市区"
                    :area-list="areaList"
                    @confirm="onConfirmArea"
                    @cancel="showAreaPicker = false"
                  />
                </van-popup>
              </div>
              <div class="form-item">
                <div class="form-item-title">详细联系地址</div>
                <van-field
                  v-model="address"
                  name="address"
                  placeholder="例如：**街**号"
                />
              </div>
            </div>
          </div>
          <div class="form-wrapper-content">
            <p class="form-wrapper-content-title">送检信息</p>
            <div class="form-item">
              <div class="form-item-title">送检医院</div>
              <van-field
                v-model="hospital"
                name="hospital"
                placeholder="请输入"
                :readonly="!isStepOne"
              />
            </div>
            <div class="form-item">
              <div class="form-item-title">科室名称</div>
              <van-field
                v-model="department"
                name="department"
                placeholder="请输入"
                :readonly="!isStepOne"
              />
            </div>
            <div class="form-item">
              <div class="form-item-title">医生姓名</div>
              <van-field
                v-model="doctor"
                name="doctor"
                placeholder="请输入"
                :readonly="!isStepOne"
              />
            </div>
          </div>
        </div>
        <div class="form-wrapper">
          <div class="form-wrapper-header">
            <span class="title-icon"></span>
            <span class="title">样本信息</span>
          </div>
          <div class="form-wrapper-content">
            <p class="form-wrapper-content-title">肿瘤信息</p>
            <div class="form-item">
              <div class="form-item-title">肿瘤类型</div>
              <van-field
                v-model="tumortype"
                name="tumortype"
                readonly
                clickable
                placeholder="请输入"
              >
                <template #button>
                  <span
                    class="btn-choose"
                    v-show="isStepOne"
                    @click="handlerTumorSearchModal()"
                    >选择</span
                  >
                </template>
              </van-field>
            </div>
            <div class="form-item">
              <div class="form-item-title">肿瘤分期</div>
              <van-field
                v-model="level"
                readonly
                clickable
                name="level"
                placeholder="请输入"
              >
                <template #button>
                  <span
                    class="btn-choose"
                    v-show="isStepOne"
                    @click="handlerStageSearchModal()"
                    >选择</span
                  >
                </template>
              </van-field>
            </div>
          </div>
          <div class="form-wrapper-content">
            <p class="form-wrapper-content-title">吸烟程度</p>
            <div class="form-item">
              <van-radio-group v-model="smokeDegress" :disabled="!isStepOne">
                <van-radio name="不吸烟">不吸烟（不抽或偶尔抽烟）</van-radio>
                <van-radio name="轻度">轻度（每天&lt;10根）</van-radio>
                <van-radio name="重度">重度（每天&ge;10根）</van-radio>
              </van-radio-group>
            </div>
          </div>
          <div class="form-wrapper-content">
            <p class="form-wrapper-content-title">检测史</p>
            <div class="form-item">
              <van-cell center title="之前是否有做过检测">
                <template #right-icon>
                  <van-switch
                    v-model="detectionStatus"
                    :disabled="!isStepOne"
                    size="24"
                  />
                </template>
              </van-cell>
            </div>
            <div v-if="detectionStatus">
              <div class="form-item">
                <div class="form-item-title">是否有做过PD-L1</div>
                <van-field name="radio">
                  <template #input>
                    <div class="w-100">
                      <van-radio-group
                        v-model="detectionPDL1Status"
                        :disabled="!isStepOne"
                        direction="horizontal"
                      >
                        <van-row type="flex" align="center" class="w-100">
                          <van-col span="12">
                            <van-radio name="是">是</van-radio>
                          </van-col>
                          <van-col span="12">
                            <van-radio name="否">否</van-radio>
                          </van-col>
                        </van-row>
                      </van-radio-group>
                    </div>
                  </template>
                </van-field>
              </div>
              <div v-if="detectionPDL1Status === '是'">
                <div class="form-item">
                  <div class="form-item-title">PD-L1表达值</div>
                  <van-field
                    v-model="detectionPDL1Value"
                    name="detectionPDL1Value"
                    :readonly="!isStepOne"
                    placeholder="请输入"
                  />
                </div>
                <div class="form-item">
                  <div class="form-item-title">检测时间</div>
                  <van-field
                    v-model="detectionPDL1Time"
                    name="detectionPDL1Time"
                    placeholder="请输入"
                  >
                    <template #button>
                      <span
                        class="btn-choose"
                        v-show="isStepOne"
                        @click="handlerCommonDatePicker('detectionPDL1Time')"
                        >选择</span
                      >
                    </template>
                  </van-field>
                </div>
              </div>
              <div class="form-item">
                <div class="form-item-title">是否MSI/dMMR检测</div>
                <van-field name="radio">
                  <template #input>
                    <div class="w-100">
                      <van-radio-group
                        v-model="detectionMSIOrDMMRStatus"
                        :disabled="!isStepOne"
                        direction="horizontal"
                      >
                        <van-row type="flex" align="center" class="w-100">
                          <van-col span="12">
                            <van-radio name="是">是</van-radio>
                          </van-col>
                          <van-col span="12">
                            <van-radio name="否">否</van-radio>
                          </van-col>
                        </van-row>
                      </van-radio-group>
                    </div>
                  </template>
                </van-field>
              </div>
              <div v-if="detectionMSIOrDMMRStatus === '是'">
                <div class="form-item">
                  <div class="form-item-title">检测结果</div>
                  <van-field
                    v-model="detectionMSIOrDMMRValue"
                    name="detectionMSIOrDMMRValue"
                    :readonly="!isStepOne"
                    placeholder="请输入"
                  />
                </div>
                <div class="form-item">
                  <div class="form-item-title">检测时间</div>
                  <van-field
                    v-model="detectionMSIOrDMMRTime"
                    name="detectionMSIOrDMMRTime"
                    placeholder="请输入"
                  >
                    <template #button>
                      <span
                        class="btn-choose"
                        v-show="isStepOne"
                        @click="
                          handlerCommonDatePicker('detectionMSIOrDMMRTime')
                        "
                        >选择</span
                      >
                    </template>
                  </van-field>
                </div>
              </div>
              <div class="form-item">
                <div class="form-item-title">是否做过其他基因检测</div>
                <van-field name="radio">
                  <template #input>
                    <div class="w-100">
                      <van-radio-group
                        v-model="detectionOtherStatus"
                        direction="horizontal"
                        :disabled="!isStepOne"
                      >
                        <van-row type="flex" align="center" class="w-100">
                          <van-col span="12">
                            <van-radio name="是">是</van-radio>
                          </van-col>
                          <van-col span="12">
                            <van-radio name="否">否</van-radio>
                          </van-col>
                        </van-row>
                      </van-radio-group>
                    </div>
                  </template>
                </van-field>
              </div>
              <div v-if="detectionOtherStatus === '是'">
                <div class="form-item">
                  <div class="form-item-title">检测结果</div>
                  <van-field
                    v-model="detectionOtherValue"
                    name="detectionOtherValue"
                    placeholder="请输入"
                    :readonly="!isStepOne"
                  />
                </div>
                <div class="form-item">
                  <div class="form-item-title">检测时间</div>
                  <van-field
                    v-model="detectionOtherTime"
                    name="detectionOtherTime"
                    @click="handlerCommonDatePicker('detectionOtherTime')"
                    placeholder="请输入"
                  >
                    <template #button>
                      <span
                        class="btn-choose"
                        v-show="isStepOne"
                        @click="handlerCommonDatePicker('detectionOtherTime')"
                        >选择</span
                      >
                    </template>
                  </van-field>
                </div>
              </div>
            </div>
          </div>
          <div class="form-wrapper-content">
            <p class="form-wrapper-content-title">家族史</p>
            <div class="form-item">
              <van-cell center title="是否有亲属得过癌症">
                <template #right-icon>
                  <van-switch
                    v-model="familyMemberCancerStatus"
                    :disabled="!isStepOne"
                    size="24"
                  />
                </template>
              </van-cell>
            </div>
            <div v-if="familyMemberCancerStatus">
              <div
                v-for="(item, index) in familyMemberCancerList"
                :key="index"
                class="family-member-item"
              >
                <p class="title">
                  <span>亲属{{ index + 1 }}</span
                  ><van-icon
                    name="minus"
                    v-if="index > 0 && step !== 2"
                    @click="deleteFamilyMemberItem(index)"
                  />
                </p>
                <div class="form-item">
                  <div class="form-item-title">类型</div>
                  <van-field
                    name="tumortype"
                    placeholder="请输入"
                    readonly
                    clickable
                    :value="item.tumortype"
                  >
                    <template #button>
                      <span
                        class="btn-choose"
                        v-show="toStepOne"
                        @click="handlerTumorSearchModal('families', index)"
                        >选择</span
                      >
                    </template>
                  </van-field>
                </div>
                <div class="form-item">
                  <div class="form-item-title">亲属称谓</div>
                  <van-field
                    name="relation"
                    placeholder="请输入"
                    readonly
                    clickable
                    :value="item.relation"
                  >
                    <template #button>
                      <span
                        class="btn-choose"
                        v-show="toStepOne"
                        @click="handlerFamilySearchModal(index)"
                        >选择</span
                      >
                    </template>
                  </van-field>
                </div>
                <div class="form-item">
                  <div class="form-item-title">确诊时间</div>
                  <van-field
                    readonly
                    clickable
                    name="datetimePicker"
                    :value="item.date_of_diagnosis"
                    placeholder="请选择"
                  >
                    <template #button>
                      <span
                        class="btn-choose"
                        v-show="isStepOne"
                        @click="setCurrentFamilyMemberTarget(index)"
                        >选择</span
                      >
                    </template>
                  </van-field>
                  <van-popup v-model="familyMemberDayPicker" position="bottom">
                    <van-datetime-picker
                      type="date"
                      :min-date="minDate"
                      :max-date="maxDate"
                      :formatter="formatter"
                      v-model="currentDate"
                      title="确诊时间"
                      @confirm="setFamilyMemberGetCancelTime"
                      @cancel="familyMemberDayPicker = false"
                    />
                  </van-popup>
                </div>
              </div>
              <div
                class="btn-add-family-member"
                v-if="isStepOne"
                @click="addFamilyMemberItem"
              >
                <van-icon name="plus" />添加亲属患癌信息
              </div>
            </div>
          </div>
          <div class="form-wrapper-content">
            <p class="form-wrapper-content-title">手术史</p>
            <div class="form-item">
              <van-cell center title="之前是否有做过手术">
                <template #right-icon>
                  <van-switch
                    v-model="operationStatus"
                    :disabled="!isStepOne"
                    size="24"
                  />
                </template>
              </van-cell>
            </div>
            <div v-if="operationStatus">
              <div class="form-item">
                <div class="form-item-title">手术次数</div>
                <van-field name="radio">
                  <template #input>
                    <van-radio-group
                      v-model="operationNum"
                      direction="horizontal"
                      :disabled="!isStepOne"
                    >

                      <van-radio :name="option.val" v-for="(option,index) in operationNumOptions" :key="index">{{option.label}}</van-radio>
                    </van-radio-group>
                  </template>
                </van-field>
              </div>
              <div class="form-item">
                <div class="form-item-title">手术名称</div>
                <van-field
                  v-model="operationName"
                  name="operationName"
                  :readonly="!isStepOne"
                  placeholder="请输入"
                />
              </div>
              <div class="form-item">
                <div class="form-item-title">最近手术日期</div>
                <van-field
                  readonly
                  clickable
                  name="datetimePicker"
                  :value="operationDate"
                  placeholder="请选择"
                >
                  <template #button>
                    <span
                      class="btn-choose"
                      v-show="isStepOne"
                      @click="handlerCommonDatePicker('operationDate')"
                      >选择</span
                    >
                  </template>
                </van-field>
              </div>
            </div>
          </div>
          <div class="form-wrapper-content">
            <p class="form-wrapper-content-title">放疗史</p>
            <div class="form-item">
              <van-cell center title="之前是否有做过放疗">
                <template #right-icon>
                  <van-switch
                    v-model="radiationTherapyStatus"
                    :disabled="!isStepOne"
                    size="24"
                  />
                </template>
              </van-cell>
            </div>
            <div v-if="radiationTherapyStatus">
              <div class="form-item">
                <div class="form-item-title">放疗类型</div>
                <van-field
                  v-model="radiationTherapyType"
                  name="radiationTherapyType"
                  :readonly="!isStepOne"
                  placeholder="请输入"
                />
              </div>
              <div class="form-item">
                <div class="form-item-title">放疗时间</div>
                <van-row type="flex" align="center">
                  <van-col span="10">
                    <van-field
                      readonly
                      clickable
                      name="datetimePicker"
                      :value="radiationTherapyStartDate"
                      placeholder="放疗开始时间"
                    >
                      <template #button>
                        <span
                          class="btn-choose"
                          v-show="isStepOne"
                          @click="
                            handlerCommonDatePicker('radiationTherapyStartDate')
                          "
                          >选择</span
                        >
                      </template>
                    </van-field>
                  </van-col>
                  <van-col span="4" style="text-align: center">
                    <span class="time-split-symbol">-</span>
                  </van-col>
                  <van-col span="10">
                    <van-field
                      readonly
                      clickable
                      name="datetimePicker"
                      :value="radiationTherapyEndDate"
                      placeholder="放疗结束时间"
                    >
                      <template #button>
                        <span
                          class="btn-choose"
                          v-show="isStepOne"
                          @click="
                            handlerCommonDatePicker('radiationTherapyEndDate')
                          "
                          >选择</span
                        >
                      </template>
                    </van-field>
                  </van-col>
                </van-row>
              </div>
            </div>
          </div>
          <div class="form-wrapper-content">
            <p class="form-wrapper-content-title">化疗史</p>
            <div class="form-item">
              <van-cell center title="之前是否有做过化疗">
                <template #right-icon>
                  <van-switch
                    v-model="chemotherapyStatus"
                    :disabled="!isStepOne"
                    size="24"
                  />
                </template>
              </van-cell>
            </div>
            <div v-if="chemotherapyStatus">
              <div class="form-item">
                <div class="form-item-title">用药状况</div>
                <van-field name="radio">
                  <template #input>
                    <div class="chemotherapyDrug w-100">
                      <van-radio-group
                        v-model="chemotherapyDrugStatus"
                        direction="horizontal"
                        :disabled="!isStepOne"
                      >
                        <van-row type="flex" align="center" class="w-100">
                          <van-col span="12" class="chemotherapyDrugItem">
                            <van-radio name="术前">术前</van-radio>
                          </van-col>
                          <van-col span="12" class="chemotherapyDrugItem">
                            <van-radio name="术后辅助">术后辅助</van-radio>
                          </van-col>
                          <van-col span="12" class="chemotherapyDrugItem">
                            <van-radio name="一线">一线</van-radio>
                          </van-col>
                          <van-col span="12" class="chemotherapyDrugItem">
                            <van-radio name="二线">二线</van-radio>
                          </van-col>
                          <van-col span="12" class="chemotherapyDrugItem">
                            <van-radio name="三线">三线</van-radio>
                          </van-col>
                          <van-col span="12" class="chemotherapyDrugItem">
                            <van-radio name="四线">四线</van-radio>
                          </van-col>
                        </van-row>
                      </van-radio-group>
                    </div>
                  </template>
                </van-field>
              </div>
              <div class="form-item">
                <div class="form-item-title">化疗时间</div>
                <van-row type="flex" align="center">
                  <van-col span="10">
                    <van-field
                      readonly
                      clickable
                      name="datetimePicker"
                      :value="chemotherapyStartDate"
                      placeholder="化疗开始时间"
                    >
                      <template #button>
                        <span
                          class="btn-choose"
                          v-show="isStepOne"
                          @click="
                            handlerCommonDatePicker('chemotherapyStartDate')
                          "
                          >选择</span
                        >
                      </template>
                    </van-field>
                  </van-col>
                  <van-col span="4" style="text-align: center">
                    <span class="time-split-symbol">-</span>
                  </van-col>
                  <van-col span="10">
                    <van-field
                      readonly
                      clickable
                      name="datetimePicker"
                      :value="chemotherapyEndDate"
                      placeholder="化疗结束时间"
                    >
                      <template #button>
                        <span
                          class="btn-choose"
                          v-show="isStepOne"
                          @click="
                            handlerCommonDatePicker('chemotherapyEndDate')
                          "
                          >选择</span
                        >
                      </template>
                    </van-field>
                  </van-col>
                </van-row>
              </div>
            </div>
          </div>
          <div class="form-wrapper-content">
            <p class="form-wrapper-content-title">靶向治疗史</p>
            <div class="form-item">
              <van-cell center title="之前是否有做过靶向治疗">
                <template #right-icon>
                  <van-switch
                    v-model="targetedTherapyStatus"
                    :disabled="!isStepOne"
                    size="24"
                  />
                </template>
              </van-cell>
            </div>
            <div v-if="targetedTherapyStatus">
              <div class="form-item">
                <div class="form-item-title">药物名称</div>
                <van-field
                  v-model="targetedTherapyDrug"
                  name="targetedTherapyDrug"
                  :readonly="!isStepOne"
                  placeholder="请输入"
                  @focus="handlerDrugSearchModal('targetedTherapyDrug')"
                />
              </div>
              <div class="form-item">
                <div class="form-item-title">治疗时间段</div>
                <van-row type="flex" align="center">
                  <van-col span="10">
                    <van-field
                      readonly
                      clickable
                      name="datetimePicker"
                      :value="targetedTherapyStartDate"
                      placeholder="治疗开始时间"
                    >
                      <template #button>
                        <span
                          class="btn-choose"
                          v-show="isStepOne"
                          @click="
                            handlerCommonDatePicker('targetedTherapyStartDate')
                          "
                          >选择</span
                        >
                      </template>
                    </van-field>
                  </van-col>
                  <van-col span="4" style="text-align: center">
                    <span class="time-split-symbol">-</span>
                  </van-col>
                  <van-col span="10">
                    <van-field
                      readonly
                      clickable
                      name="datetimePicker"
                      :value="targetedTherapyEndDate"
                      placeholder="治疗结束时间"
                    >
                      <template #button>
                        <span
                          class="btn-choose"
                          v-show="isStepOne"
                          @click="
                            handlerCommonDatePicker('targetedTherapyEndDate')
                          "
                          >选择</span
                        >
                      </template>
                    </van-field>
                  </van-col>
                </van-row>
              </div>
            </div>
          </div>
          <div class="form-wrapper-content">
            <p class="form-wrapper-content-title">免疫治疗史</p>
            <div class="form-item">
              <van-cell center title="之前是否有做过免疫治疗">
                <template #right-icon>
                  <van-switch
                    v-model="immunotherapyStatus"
                    :disabled="!isStepOne"
                    size="24"
                  />
                </template>
              </van-cell>
            </div>
            <div v-if="immunotherapyStatus">
              <div class="form-item">
                <div class="form-item-title">药物名称</div>
                <van-field
                  v-model="immunotherapyDrug"
                  name="immunotherapyDrug"
                  :readonly="!isStepOne"
                  placeholder="请输入"
                  @focus="handlerDrugSearchModal('immunotherapyDrug')"
                />
              </div>
              <div class="form-item">
                <div class="form-item-title">治疗时间段</div>
                <van-row type="flex" align="center">
                  <van-col span="10">
                    <van-field
                      readonly
                      clickable
                      name="datetimePicker"
                      :value="immunotherapyStartDate"
                      placeholder="治疗开始时间"
                    >
                      <template #button>
                        <span
                          class="btn-choose"
                          v-show="isStepOne"
                          @click="
                            handlerCommonDatePicker('immunotherapyStartDate')
                          "
                          >选择</span
                        >
                      </template>
                    </van-field>
                  </van-col>
                  <van-col span="4" style="text-align: center">
                    <span class="time-split-symbol">-</span>
                  </van-col>
                  <van-col span="10">
                    <van-field
                      readonly
                      clickable
                      name="datetimePicker"
                      :value="immunotherapyEndDate"
                      placeholder="治疗结束时间"
                    >
                      <template #button>
                        <span
                          class="btn-choose"
                          v-show="isStepOne"
                          @click="
                            handlerCommonDatePicker('immunotherapyEndDate')
                          "
                          >选择</span
                        >
                      </template>
                    </van-field>
                  </van-col>
                </van-row>
              </div>
            </div>
          </div>
          <div class="form-wrapper-content">
            <p class="form-wrapper-content-title">器官移植史</p>
            <div class="form-item">
              <van-cell center title="之前是否有器官移植">
                <template #right-icon>
                  <van-switch
                    v-model="organRemoveStatus"
                    :disabled="!isStepOne"
                    size="24"
                  />
                </template>
              </van-cell>
            </div>
            <div v-if="organRemoveStatus">
              <div class="form-item">
                <div class="form-item-title">移植器官</div>
                <van-field
                  v-model="organ"
                  :readonly="!isStepOne"
                  name="organ"
                  placeholder="请输入"
                />
              </div>
              <div class="form-item">
                <div class="form-item-title">移植时间</div>
                <van-field
                  readonly
                  clickable
                  name="datetimePicker"
                  :value="organRemoveDate"
                  placeholder="请选择"
                >
                  <template #button>
                    <span
                      class="btn-choose"
                      v-show="isStepOne"
                      @click="handlerCommonDatePicker('organRemoveDate')"
                      >选择</span
                    >
                  </template>
                </van-field>
              </div>
            </div>
          </div>
        </div>
        <div class="form-wrapper">
          <div class="form-wrapper-header">
            <span class="title-icon"></span>
            <span class="title">患者招募</span>
          </div>
          <div class="form-wrapper-content">
            <p class="form-wrapper-content-title">招募信息</p>
            <div class="form-item">
              <van-cell center title="是否愿意参加临床试验">
                <template #right-icon>
                  <van-switch
                    v-model="is_attend_test"
                    :disabled="!isStepOne"
                    size="24"
                    @change="changeAttend()"
                  />
                </template>
              </van-cell>
            </div>
            <div v-if="is_attend_test">
                <van-cell center title="是否接受电话回访">
                <template #right-icon>
                  <van-switch
                    v-model="is_phone_return"
                    :disabled="!isStepOne"
                    size="24"
                  />
                </template>
              </van-cell>
            </div>
          </div>
        </div>
        <!-- 公共的日期选择器 -->
        <van-popup v-model="showCommonDayPicker" position="bottom">
          <van-datetime-picker
            type="date"
            :min-date="minDate"
            :max-date="maxDate"
            :formatter="formatter"
            v-model="currentDate"
            title="选择年月日"
            @confirm="onConfirmCommonDate"
            @cancel="showCommonDayPicker = false"
          />
        </van-popup>
        <div style="margin: 16px" v-if="step === 1">
          <van-button
            round
            block
            type="info"
            native-type="button"
            class="theme-btn"
            @click="toStepTwo"
            >下一步</van-button
          >
          <!-- <van-button
            round
            block
            type="info"
            native-type="button"
            class="theme-btn"
            @click="toSign"
            >下一步</van-button
          > -->
        </div>
        <van-row type="flex" justify="space-between" v-else-if="step === 2">
          <van-col span="8"
            ><van-button
              round
              block
              type="info"
              native-type="button"
              class="back-btn"
              @click="toStepOne"
              >返回修改</van-button
            ></van-col
          >
          <van-col span="12"
            ><van-button
              round
              block
              type="info"
              native-type="button"
              class="theme-btn"
              @click="handlerConfirmAgreementModal"
              >确认填写信息无误</van-button
            ></van-col
          >
        </van-row>
      </van-form>
    </div>
    <confrimAgreementModal ref="confirmAgreement" @confirmAgree="toSign" />
    <!-- 肿瘤分期搜索弹出层 -->
    <stageSearchModal
      ref="stageSearchModal"
      :placeholder="stageSearchModalData.placeholder"
      :searchTargetList="stageSearchModalData.searchTargetList"
      @confirm="confirmStageSearch"
    />
    <!-- 肿瘤类型搜索弹出层 -->
    <tumorSearchModal
      ref="tumorSearchModal"
      :placeholder="tumorSearchModalData.placeholder"
      :searchTargetList="tumorSearchModalData.searchTargetList"
      @confirm="confirmTumorSearch"
    />
    <!-- 家庭成员类型搜索弹出层 -->
    <familySearchModal
      ref="familySearchModal"
      :placeholder="familySearchModalData.placeholder"
      :searchTargetList="familySearchModalData.searchTargetList"
      @confirm="confirmFamiliySearch"
    />
    <!-- 肿瘤分期搜索弹出层 -->
    <drugSearchModal
      ref="drugSearchModal"
      :placeholder="drugSearchModalData.placeholder"
      :searchTargetList="drugSearchModalData.searchTargetList"
      @confirm="confirmDrugSearch"
    />
  </div>
</template>
<script>
import confrimAgreementModal from "./components/confrimAgreementModal";
import stageSearchModal from "./components/stageSearchModal";
import tumorSearchModal from "./components/tumorSearchModal";
import familySearchModal from "./components/familySearchModal";
import drugSearchModal from "./components/drugSearchModal";
import { Local } from "@/utils/common.js";
export default {
  components: {
    confrimAgreementModal,
    stageSearchModal,
    tumorSearchModal,
    familySearchModal,
    drugSearchModal,
  },
  data() {
    return {
      step: 1,
      showConfirmAgreementModal: true,
      isDisabled: true,
      showMask:false,
      areaList: {},
      minBirthDate: new Date(1900, 0, 1),
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(),
      // minDate: new Date(1970, 0, 1),
      // maxDate: new Date(),
      currentDate: new Date(),
      showBirthDatePicker: false,
      showAreaPicker: false,
      showCommonDayPicker: false,
      commonDayTarget: "",
      organRemoveStatus: false,
      organRemoveDate: "",
      organ: "",
      level: "",
      tumortype: "",
      targetedTherapyStatus: false,
      targetedTherapyDrug: "",
      targetedTherapyStartDate: "",
      targetedTherapyEndDate: "",
      immunotherapyStatus: false,
      immunotherapyDrug: "",
      immunotherapyStartDate: "",
      immunotherapyEndDate: "",
      radiationTherapyStatus: false,
      radiationTherapyType: "",
      radiationTherapyStartDate: "",
      radiationTherapyEndDate: "",
      operationStatus: false,
      operationNum: "",
      operationNumOptions:[
        {val:false,label:'一次'},
         {val:true,label:'多次'},
      ],
      operationName: "",
      operationDate: "",
      chemotherapyStatus: false,
      chemotherapyDrugStatus: "",
      chemotherapyStartDate: "",
      chemotherapyEndDate: "",
      detectionStatus: false,
      detectionPDL1Status: "",
      detectionPDL1Value: "",
      detectionPDL1Time: "",
      detectionMSIOrDMMRStatus: "",
      detectionMSIOrDMMRValue: "",
      detectionMSIOrDMMRTime: "",
      detectionOtherStatus: "",
      detectionOtherValue: "",
      detectionOtherTime: "",
      familyMemberCancerStatus: false,
      currentFamilyMemberItem: 0,
      familyMemberDayPicker: false,
      familyMemberCancerList: [
        {
          tumortype: "",
          relation: "",
          date_of_diagnosis: "",
        },
      ],
      patientname: "",
      gender: "",
      birth_date: "",
      ID: "",
      contacts1: "患者本人",
      phone: this.$route.query.phone || "",
      address: "",
      hospital: "",
      department: "",
      doctor: "",
      smokeDegress: "",
      district: "",
      tumorSearchModalData: {
        placeholder: "请输入肿瘤类型",
        searchTargetList: [],
      },
      stageSearchModalData: {
        placeholder: "请输入肿瘤分期",
        searchTargetList: [],
      },
      drugSearchModalData: {
        placeholder: "请输入药物名称",
        searchTargetList: [],
      },
      familySearchModalData: {
        placeholder: "请输入亲属类型",
        searchTargetList: [],
      },
      searchModalData: {
        placeholder: "",
        searchTargetList: [],
      },
      currentSearchTumorTarget: "",
      currentDrugTarget: "",
      is_attend_test: "",
      is_phone_return: "",
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    toSign() {
      //可以直接使用的key
      let testRequestFormData =  Object.assign({}, this.$data);
       let keyMap = [
        "minDate",
        "maxDate",
        "currentDate",
        "tumorSearchModalData",
        "stageSearchModalData",
        "drugSearchModalData",
        "familySearchModalData",
        "areaList"
      ];
      keyMap.forEach((key)=>{
         delete testRequestFormData[key]
      })
      Local.set("testRequestFormData", testRequestFormData);
      this.submitFormRequest();
    },
    setIdInfo(id) {
      let _this = this;
      if(!id){
        return
      }
      let info = this.getAgeAndGender(id);
      for(let key in info){
        _this[key] = info[key];
      }
    },
    getAgeAndGender(idCardNo) {
        // 将身份证号码转换为大写  
        idCardNo = idCardNo.toUpperCase();
        var gender = null;
        var birth_date = null;
        // 判断身份证号码长度是否为18位  
        if (!(/(^\d{17}([0-9]|X)$)/.test(idCardNo))) {
            return { birth_date: null, gender: null };
        }
        // 计算年龄  
        var birthYear = idCardNo.substr(6, 4); // 例如：1990  
        var birthMonth = idCardNo.substr(10, 2); // 例如：03  

        // 计算持有人的年龄  
        birth_date = birthYear + '-' + birthMonth;
        
        // 判断性别  
        if (idCardNo.slice(16, 17) % 2 === 1) {
            gender = '男';
        } else {
            gender = '女';
        }

        return { birth_date: birth_date, gender: gender, ID: idCardNo };
    },
    submitFormRequest() {
      let testRequestFormData = Local.get("testRequestFormData");
      let keyMap = [
        "patientname",
        "gender",
        "birth_date",
        "ID",
        "contacts1",
        "phone",
        "hospital",
        "department",
        "doctor",
        "level",
        "tumortype",
        "smokeDegress",
        "district",
        "address",
        "is_attend_test",
        "is_phone_return"
      ];
      let formData = {};
      for (let key in testRequestFormData) {
        if (keyMap.indexOf(key) !== -1) {
          formData[key] = testRequestFormData[key];
        }
      }

      //如果有检测史
      if (testRequestFormData.detectionStatus) {
        let testing = [];
        if (testRequestFormData.detectionPDL1Status === "是") {
          testing.push({
            subject: "PD-L1",
            result: testRequestFormData.detectionPDL1Value,
            date: testRequestFormData.detectionPDL1Time,
          });
        }
        if (testRequestFormData.detectionMSIOrDMMRStatus === "是") {
          testing.push({
            subject: "MSI/dMMR",
            result: testRequestFormData.detectionMSIOrDMMRValue,
            date: testRequestFormData.detectionMSIOrDMMRTime,
          });
        }
        if (testRequestFormData.detectionOtherStatus === "是") {
          testing.push({
            subject: "其他",
            result: testRequestFormData.detectionOtherValue,
            date: testRequestFormData.detectionOtherTime,
          });
        }
        formData.testing = testing;
      }
      //家族史
      if (testRequestFormData.familyMemberCancerStatus) {
        let families = [];
        testRequestFormData.familyMemberCancerList.forEach((item) => {
          if (item.date_of_diagnosis && item.relation && item.tumortype) {
            families.push(item);
          }
        });
        formData.families = families;
      }
      //手术史
      if (testRequestFormData.operationStatus) {
        //operationName 手术名称 operationDate手术时间  operationNum手术次数 多次true 一次false
        formData.recent_operation = {
          name: testRequestFormData.operationName,
          date: testRequestFormData.operationDate,
          multiple: testRequestFormData.operationNum,
        };
      }
      //放疗史
      if (testRequestFormData.radiationTherapyStatus) {
        //radiationTherapyType 放疗类型 开始时间  radiationTherapyStartDate  结束时间radiationTherapyEndDate
        let radiotherapies = [];
        if (
          testRequestFormData.radiationTherapyType &&
          testRequestFormData.radiationTherapyStartDate 
        ) {
          let item = {
            type: testRequestFormData.radiationTherapyType,
            start: testRequestFormData.radiationTherapyStartDate,
            end: testRequestFormData.radiationTherapyEndDate,
          };
          radiotherapies.push(item);
        }
        formData.radiotherapies = radiotherapies;
      }
       //化疗史
      if (testRequestFormData.chemotherapyStatus) {
        //chemotherapyDrugStatus 用药状态 开始时间  chemotherapyStartDate 结束时间chemotherapyEndDate
        let chemotherapies = [];
        if (
          testRequestFormData.chemotherapyDrugStatus &&
          testRequestFormData.chemotherapyStartDate 
        ) {
          let item = {
            treatment: testRequestFormData.chemotherapyDrugStatus,
            start: testRequestFormData.chemotherapyStartDate,
            end: testRequestFormData.chemotherapyEndDate,
          };
          chemotherapies.push(item);
        }
        formData.chemotherapies = chemotherapies;
      }
      //靶向治疗史
      if (testRequestFormData.targetedTherapyStatus) {
        //targetedTherapyDrug 药物名称 开始时间  targetedTherapyStartDate 结束时间 targetedTherapyEndDate
        let targeted_therapies = [];
        if (
          testRequestFormData.targetedTherapyDrug &&
          testRequestFormData.targetedTherapyStartDate 
        ) {
          let item = {
            type: testRequestFormData.targetedTherapyDrug,
            start: testRequestFormData.targetedTherapyStartDate,
            end: testRequestFormData.targetedTherapyEndDate,
          };
          targeted_therapies.push(item);
        }
        formData.targeted_therapies = targeted_therapies;
      }
       //免疫治疗史
      if (testRequestFormData.immunotherapyStatus) {
        //immunotherapyDrug 药物名称 开始时间  immunotherapyStartDate 结束时间 immunotherapyEndDate
        let immunotherapies = [];
        if (
          testRequestFormData.immunotherapyDrug &&
          testRequestFormData.immunotherapyStartDate 
        ) {
          let item = {
            type: testRequestFormData.immunotherapyDrug,
            start: testRequestFormData.immunotherapyStartDate,
            end: testRequestFormData.immunotherapyEndDate,
          };
          immunotherapies.push(item);
        }
        formData.immunotherapies = immunotherapies;
      }
        //器官移植史
      if (testRequestFormData.organRemoveStatus) {
        //organ 移植器官   organRemoveDate  移植时间 
        let transplants  = [];
        if (
          testRequestFormData.organ &&
          testRequestFormData.organRemoveDate 
        ) {
          let item = {
            organ: testRequestFormData.organ,
            date: testRequestFormData.organRemoveDate,
          };
          transplants.push(item);
        }
        formData.transplants  = transplants ;
      }
      //本地开发的时候添加patient_id 跳过后端的登陆校验
      // formData.patient_id = '20201212'
      this.$request.post(this.$api.submitTestForm, formData).then(() => {
        // 此处只接收成功数据，失败数据不返回
        Local.remove("testRequestFormData");
        setTimeout(()=>{
          //  this.$router.back(-1)
           this.$router.replace("/submitFormSuccess")
        },3500)
      });
      // console.log(formData);
    },
    getLocalFormData() {
     let testRequestFormData =  Local.get("testRequestFormData");
      Object.assign(this.$data, testRequestFormData);
    },
    onConfirm(time) {
      this.birth_date = this.$dayjs(time).format("YYYY-MM");
      this.showBirthDatePicker = false;
    },
    onConfirmArea(values) {
      this.district = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join(" ");
      this.showAreaPicker = false;
    },
    onConfirmCommonDate(time) {
      this[this.commonDayTarget] = this.$dayjs(time).format("YYYY-MM-DD");
      this.showCommonDayPicker = false;
    },
    handlerCommonDatePicker(target) {
      this.commonDayTarget = target;
      this.showCommonDayPicker = true;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    setCurrentFamilyMemberTarget(index) {
      this.currentFamilyMemberItem = index;
      this.familyMemberDayPicker = true;
    },
    setFamilyMemberGetCancelTime(time) {
      this.familyMemberCancerList[this.currentFamilyMemberItem][
        "date_of_diagnosis"
      ] = this.$dayjs(time).format("YYYY-MM-DD");
      this.familyMemberDayPicker = false;
    },
    addFamilyMemberItem() {
      let item = {
        tumortype: "",
        relation: "",
        date_of_diagnosis: "",
      };
      this.familyMemberCancerList.push(item);
    },
    deleteFamilyMemberItem(index) {
      this.familyMemberCancerList.splice(index, 1);
    },
    handlerDrugSearchModal(drugTarget) {
      this.currentDrugTarget = drugTarget;
      //药物搜索弹出层显示
      this.$refs.drugSearchModal.showModal();
    },
    confirmDrugSearch(val) {
      //确认搜索药物回调函数
      this[this.currentDrugTarget] = val;
    },
    handlerFamilySearchModal(index) {
      //亲属类型搜索弹出层显示
      this.currentFamilyMemberItem = index;
      this.$refs.familySearchModal.showModal();
    },
    confirmFamiliySearch(val) {
      //确认亲属类型回调函数
      this.familyMemberCancerList[this.currentFamilyMemberItem]["relation"] =
        val;
    },
    handlerTumorSearchModal(target, index) {
      //肿瘤类型搜索弹出层显示
      if (target && target === "families") {
        this.currentSearchTumorTarget = target;
        this.currentFamilyMemberItem = index;
      }
      this.$refs.tumorSearchModal.showModal();
    },
    confirmTumorSearch(val) {
      //确认搜索肿瘤类型回调函数
      if (this.currentSearchTumorTarget === "families") {
        this.familyMemberCancerList[this.currentFamilyMemberItem][
          "tumortype"
        ] = val;
      } else {
        this.tumortype = val;
      }
    },
    handlerStageSearchModal() {
      //肿瘤分期搜索弹出层显示
      this.$refs.stageSearchModal.showModal();
    },
    confirmStageSearch(val) {
      //确认搜索肿瘤分期回调函数
      this.level = val;
    },
    handlerConfirmAgreementModal() {
      this.$refs.confirmAgreement.showModal();
    },
    validateInfo(){
      let info = [
        {
          key:'patientname',
          message:'姓名不能为空'
        },
        {
          key:'gender',
          message:'请选择性别'
        },
        {
          key:'birth_date',
          message:'请选择出生年月'
        },
      ];

      let flag = info.some((v)=>{
        if(!this[v.key]){
          this.$toast({message: v.message});
          return true;
        }
      });

      return flag;

    },
    toStepTwo() {
      if(this.validateInfo()){
        return;
      }
      this.showMask = true;
      this.stepHandler(2);
      this.$toast({
        message:"请确认填写信息无误",
        forbidClick: true
      });
      this.backTop();
    },
    stepHandler(step) {
      this.step = step;
    },
    toStepOne() {
      this.$toast({
        message:"修改录入信息",
        forbidClick: true
      });
      this.stepHandler(1);
      this.backTop();
    },
    backTop() {
      /**
       * 回到顶部功能实现过程：
       * 1. 获取页面当前距离顶部的滚动距离
       * 2. 计算出每次向上移动的距离，用负的滚动距离除以5，因为滚动的距离是一个正数，想向上移动就是做一个减法
       * 3. 用当前距离加上计算出的距离，然后赋值给当前距离，就可以达到向上移动的效果
       * 4. 最后记得在移动到顶部时，清除定时器
       **/
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    getTumors() {
      //获取肿瘤类型（癌种）列表
      this.$request.get(this.$api.tumors).then((res) => {
        this.tumorSearchModalData.searchTargetList = res;
      });
    },
    getDrugs() {
      //获取药物列表
      this.$request.get(this.$api.drugs).then((res) => {
        this.drugSearchModalData.searchTargetList = res;
      });
    },
    getStages() {
      //肿瘤分期（staging）列表
      this.$request.get(this.$api.stages).then((res) => {
        this.stageSearchModalData.searchTargetList = res;
      });
    },
    getRegions() {
      //获取省市区
      this.$request.get(this.$api.regions).then((res) => {
        let province_list = {};
        let city_list = {};
        let county_list = {};
        //过滤不符合的地区
        let filterArr = ['台湾省','香港特别行政区','澳门特别行政区'];
        //格式化数据
        res.filter((v)=>{
          return filterArr.indexOf(v.name) === -1;
        }).forEach((v)=>{
          province_list[v.adcode] = v.name;
          v.districts.forEach((dtc)=>{
            city_list[dtc.adcode] = dtc.name;
            dtc.districts.forEach((county)=>{
              county_list[county.adcode] = county.name;
            });
          });
        });

        this.areaList = {
          province_list: province_list,
          city_list: city_list,
          county_list: county_list,
        };
        // this.stageSearchModalData.searchTargetList = res;
      });
    },
    relatives() {
      //获取亲属选项列表
      this.$request.get(this.$api.relatives).then((res) => {
        this.familySearchModalData.searchTargetList = res;
      });
    },
    changeAttend() {
      if(!this.is_attend_test){
          this.is_phone_return = false;
      }
    },
    initData() {
      this.getTumors();
      this.getDrugs();
      this.getStages();
      this.getRegions();
      this.relatives();
      this.getLocalFormData()
    },
  },
  computed: {
    isStepOne() {
      return this.step === 1;
    },
  },
};
</script>
<style lang="less" scoped>
// @import "~assets/less/variable.less";
.test-request-form {
  /deep/ .van-radio__label {
    color: @color-26;
    font-weight: 400;
    font-size: 34px;
  }
  /deep/ .van-radio__icon {
    font-size: 32px;
  }
  /deep/ .van-radio__icon--checked .van-icon {
    color: #fff;
    background-color: @theme-color;
    border-color: @theme-color;
  }
  /deep/ .van-picker__confirm {
    color: @theme-color;
  }
  /deep/ .van-picker-column__item--selected {
    color: @theme-color;
  }
  /deep/ .van-cell__title {
    font-size: @font-size-medium-x;
  }
  /deep/ .van-switch--on {
    background-color: @theme-color;
  }
  /deep/ input.van-field__control {
    font-size: @font-size-medium-xl;
  }
  color: @color-26;
  padding-bottom: 16px;
  .w-100 {
    width: 100%;
  }
  .theme-btn {
    background: @theme-color;
    border: 1px solid @theme-color;
  }
  .back-btn {
    background: #d4f3ea;
    border: 1px solid #d4f3ea;
    color: @theme-color;
  }
  .add-border-bottom {
    border-bottom: 15px solid #f8f8f8 !important;
  }
  .van-cell {
    padding-left: 0;
    padding-right: 0;
  }
  .van-switch {
    &.van-switch--disabled {
      background: #cccccc;
    }
  }
  .header-container {
    .banner {
      width: 100%;
      height: 356px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .step-container {
      text-align: center;
      padding-bottom: 33px;
      color: @color-26;
      font-size: @font-size-medium;
      .step-icon-row {
        .step-icon {
          display: inline-block;
          width: 40px;
          height: 40px;
          line-height: 40px;
          background: #e1e1e1;
          border-radius: 50%;
          color: @color-26;
          &.active {
            background: @theme-color;
            color: white;
          }
        }
      }
      .step-message-row {
      }
    }
  }
  .request-form-container {
    padding-left: 29px;
    padding-right: 29px;
    .form-wrapper {
      .form-wrapper-header {
        display: flex;
        align-items: center;
        padding-top: 29px;
        padding-bottom: 29px;
        .title-icon {
          display: inline-block;
          width: 14px;
          height: 49px;
          background: @theme-color;
          opacity: 1;
          margin-right: 29px;
        }
        .title {
          font-size: @font-size-large;
        }
      }
      .form-wrapper-content {
        .form-wrapper-content-title {
          font-size: @font-size-large-x;
          font-weight: 600;
        }
        .form-item {
          border-bottom: 1px solid #f5f5f5;
          margin-top: 30px;
          margin-bottom: 30px;
          .form-item-title {
            font-size: @font-size-medium-x;
          }
          .van-radio-group {
            .van-radio {
              margin-bottom: 30px;
            }
            &.van-radio-group--horizontal {
              .van-radio {
                margin-bottom: 0;
              }
            }
          }
          .time-split-symbol {
            font-size: @font-size-medium-xl;
          }
          .chemotherapyDrug {
            .chemotherapyDrugItem {
              margin-bottom: 50px;
            }
          }
        }
        .family-member-item {
          border-top: 15px solid #f8f8f8;
          .title {
            display: flex;
            justify-content: space-between;
            margin-top: 32px;
            font-size: 32px;
            color: @color-26;
          }
        }
        .btn-add-family-member {
          padding: 40px 0;
          color: @theme-color;
          text-align: center;
          font-size: 32px;
        }
      }
    }
    .btn-choose {
      color: @theme-color;
      font-size: @font-size-medium-xl;
    }
  }
}
</style>