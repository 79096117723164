<template>
  <div>
    <van-popup
      v-model="show"
      :style="{
        width: '80%',
        paddingLeft: '6%',
        paddingRight: '6%',
      }"
      :close-on-click-overlay="false"
    >
      <p class="header-title">受检者确认</p>
      <ul class="argeement-list">
        <li class="item" v-for="(item, index) in argeementList" :key="index">
          {{ index + 1 }}.{{ item }}
        </li>
      </ul>
      <div style="height:30px;">
        <van-count-down
          :time="time"
          :auto-start="false"
          class="count-down-row"
          @finish="finish"
          format="ss"
          ref="countDown"
          v-show="showCountDown"
        >
          <template #default="timeData">
            <span class="block">{{ timeData.seconds }}S</span>
          </template>
        </van-count-down>
      </div>
      <van-row type="flex" justify="space-between" class="btn-row">
        <van-col span="11">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            class="btn-reject"
            :disabled="disabled"
            @click="closeModal"
            >拒绝确认</van-button
          ></van-col
        >
        <van-col span="11">
          <van-button
            round
            block
            type="info"
            native-type="submit"
            class="theme-btn"
            @click="confirmAgree"
            :disabled="disabled"
            >确认</van-button
          ></van-col
        >
      </van-row>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "confrimAgreementModal",
  data() {
    return {
      disabled: true,
      time: 10000,
      showCountDown: true,
      show: false,
      argeementList: [
        "本人承诺提供的个人信息真实有效， 并知情本次填写的信息将用于深圳裕康医学检验实验室进行基因检测。",
        "我已充分了解基因检测的性质、预期目的、检测的风险和必要性。"
      ],
    };
  },
  methods: {
    showModal() {
      this.show = true;
      this.start();
    },
    closeModal() {
      this.show = false;
      this.reset();
    },
    finish() {
      // console.log("finish");
      this.disabled = false;
      this.showCountDown = false;
    },
    start() {
      this.$nextTick(() => {
        this.showCountDown = true;
       this.$refs.countDown.start()
      });
      // this.$refs.countDown.start();
    },
    confirmAgree(){
      this.$emit('confirmAgree')
    },
    reset() {
      this.$refs.countDown.reset();
    },
  },
};
</script>
<style lang="less" scoped>
.header-title {
  text-align: center;
  padding-top: 39px;
  padding-bottom: 39px;
  font-size: @font-size-large;
  color: @color-26;
  font-weight: 600;
}
.argeement-list {
  li.item {
    font-size: @font-size-medium;
    line-height: 1.8;
  }
}
.count-down-row {
  margin-bottom: 17px;
  text-align: center;
  font-size: @font-size-medium;
  color: @theme-color;
}
.btn-row {
  margin-bottom: 45px;
  font-size: @font-size-medium-xl;
  .btn-reject {
    color: @theme-color;
    background-color: #d4f3ea;
    border: 1px solid #d4f3ea;
  }
  .theme-btn {
    background: @theme-color;
    border: 1px solid @theme-color;
  }
}
</style>